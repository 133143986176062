import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/alanfung/Documents/Projects/Code/gatsby-portfolio/src/templates/portfolio.js";
import { graphql } from "gatsby";
import fa05 from "./fa-05.gif";
import fa13 from "./fa-13.gif";
export const query = graphql`
  query {
    fa1: file(relativePath: { eq: "First_American/fa-01.png" }) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    fa2: file(relativePath: { eq: "First_American/fa-02.png" }) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    fa3: file(relativePath: { eq: "First_American/fa-03.png" }) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    fa4: file(relativePath: { eq: "First_American/fa-04.png" }) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    fa6: file(relativePath: { eq: "First_American/fa-06.png" }) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    fa7: file(relativePath: { eq: "First_American/fa-07.png" }) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    fa8: file(relativePath: { eq: "First_American/fa-08.png" }) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    fa9: file(relativePath: { eq: "First_American/fa-09.png" }) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    fa10: file(relativePath: { eq: "First_American/fa-10.png" }) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    fa11: file(relativePath: { eq: "First_American/fa-11.png" }) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    fa12: file(relativePath: { eq: "First_American/fa-12.png" }) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    fa14: file(relativePath: { eq: "First_American/fa-14.png" }) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const GridTwo = makeShortcode("GridTwo");
const ImageContainer = makeShortcode("ImageContainer");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <GridTwo mdxType="GridTwo">
      <ImageContainer classes="large" fluid={props.data.fa1.childImageSharp.fluid} mdxType="ImageContainer" />
      <ImageContainer classes="small right" fluid={props.data.fa2.childImageSharp.fluid} mdxType="ImageContainer" />
      <ImageContainer classes="large" fluid={props.data.fa3.childImageSharp.fluid} mdxType="ImageContainer" />
      <ImageContainer classes="small right" fluid={props.data.fa12.childImageSharp.fluid} mdxType="ImageContainer" />
      <ImageContainer classes="small" fluid={props.data.fa8.childImageSharp.fluid} mdxType="ImageContainer" />
      <ImageContainer classes="small" fluid={props.data.fa9.childImageSharp.fluid} mdxType="ImageContainer" />
      <p className="small space-me right blog-text">The dashboard houses a lot of logic for the contractors. The design allows them to navigate quickly to different parts of the portal - from accepting new contracts to filing invoices, the idea was to help keep contractors focused in the field and less on their devices.</p>
      <ImageContainer classes="large" fluid={props.data.fa4.childImageSharp.fluid} mdxType="ImageContainer" />
      <p className="small space-me right blog-text">The appointment view allows contractors to quickly communicate with customers and update the status of their job/work order. The design utilizes a dynamic side panel to pull in content based on the contractors choices. The flow below demonstrates 2 paths a contractor might take while in the field.</p>
      <div className="small"><img src={fa05} alt="Reschedule Appointment" /></div>
      <div className="small"><img src={fa13} alt="Appointment Outcome" /></div>
      <p className="small space-me right blog-text">The left path demonstrates a contractor updating their appointment. The right path illustrates an issue and the contractor needs another contractor with a specialized skillset.</p>
      <ImageContainer classes="large" fluid={props.data.fa6.childImageSharp.fluid} mdxType="ImageContainer" />
      <p className="small space-me right blog-text">The Work Orders section of the site utilizes the same dynamic side panels.</p>
      <ImageContainer classes="small" fluid={props.data.fa7.childImageSharp.fluid} mdxType="ImageContainer" />
      <ImageContainer classes="small" fluid={props.data.fa14.childImageSharp.fluid} mdxType="ImageContainer" />
      <p className="small space-me right blog-text">The invoicing section utilizes the dynamic form fields, using dynamic content allows us to serve the user better without having to load extra content that may or may not be used.<br /><button className="page-button"><a href="https://herodigital.invisionapp.com/share/PQP60ESCYME#/screens">Check out the Prototype</a></button></p>
      <ImageContainer classes="large" fluid={props.data.fa10.childImageSharp.fluid} mdxType="ImageContainer" />
      <ImageContainer classes="small right" fluid={props.data.fa11.childImageSharp.fluid} mdxType="ImageContainer" />
      <p className="small right blog-text">Please contact me if you'd like to learn more about this project.</p>
    </GridTwo>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      